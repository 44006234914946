<template>
  <v-container class="pa-4" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative">
            <!-- <v-skeleton-loader
                                                      v-show="!zoneDetails.hasOwnProperty('image')"
                                                      height="260px"
                                                      class="rounded-t-lg"
                                                      type="image"
                                                    ></v-skeleton-loader> -->
            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="zoneDetails.hasOwnProperty('image') &&
              zoneDetails.image != '' &&
              zoneDetails.image != null
              ? zoneDetails.image
              : zoneImgURL
              " :lazy-src="zoneDetails.hasOwnProperty('image') &&
    zoneDetails.image != '' &&
    zoneDetails.image != null
    ? zoneDetails.image
    : zoneImgURL
    " alt="Parking.com garage">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="booking_header white--text text-left mr-4">
              Welcome to
              {{
                zoneDetails.hasOwnProperty("zoneName")
                ? zoneDetails.zoneName
                : ""
              }}
            </div>
          </div>
          <v-card-text class="pa-0">
            <v-form ref="detailsForm">
              <v-container fluid pa-0>
                <v-row no-gutters>
                  <v-col cols="12" class="px-4 mt-2 text-center color-black" style="font-size: 12px">
                    <p class="mb-0">
                      Please fill details to create parking pass
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 information ">
                  <v-col cols="2" class="align-self-center">
                    <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png" width="45px"
                      v-if="disableSubmitBtn">
                      <div class="row text-center align-self-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:large;font-weight:bold;color:white">1</span>
                        </div>
                      </div>
                    </v-img>
                    <v-img src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                      width="45px" v-if="!disableSubmitBtn">
                      <div class="row text-center pl-5">
                        <div class="col-12 text-center">
                          <span style="font-size:larger;font-weight:bold;color:white">✓</span>
                        </div>
                      </div>
                    </v-img>
                  </v-col>
                  <v-col cols="10" class="mt-3">
                    <p style="color:#F2555C;font-weight:bolder">Your Information</p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-0 px-3"><v-col cols="1" class="text-right align-self-end">
                    <v-icon class="material-icons-outlined">badge</v-icon> </v-col><v-col cols="11" class="px-4">
                    <v-text-field v-model="name" hide-details="auto" class="red--text" :rules="[rules.isRequired]"
                      clearable>
                      <template v-slot:label>
                        <span>Name</span>
                        <span> *</span>
                      </template>
                    </v-text-field>
                  </v-col></v-row>
                <v-row no-gutters class="py-3 px-3">
                  <v-col cols="1" class="text-right align-self-end">
                    <v-icon class="material-icons-outlined">send_to_mobile</v-icon>
                  </v-col>
                  <v-col cols="11" class="px-4 mt-4">
                    <VuePhoneNumberInput v-model="contactObj.phoneNumber" size="md" @update="onContactInput"
                      show-code-on-list :default-country-code="contactOptions.defaultCountry"
                      :color="contactOptions.color" :valid-color="contactOptions.validColor"
                      :error-color="contactOptions.errorColor" :border-radius="contactOptions.contactBorderRadius"
                      :error="!isContactValid" required :translations="contactOptions.translations" />
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-1 px-2"><v-col cols="1" class="text-right align-self-end">
                    <v-icon class="material-icons-outlined">mail</v-icon> </v-col><v-col cols="11" class="px-4">
                    <v-text-field v-model="email" hide-details="auto" :rules="[rules.isRequired, rules.isEmail]"
                      label="Email" clearable>
                      <template v-slot:label>
                        <span>Email</span>
                        <span> *</span>
                      </template></v-text-field>
                  </v-col></v-row>
                <v-row no-gutters class="pt-2 px-2"><v-col cols="1" class="text-right align-self-end">
                    <v-icon class="material-icons-outlined">pin</v-icon> </v-col><v-col cols="11" class="px-4">
                    <v-text-field v-model="licencePlate" :rules="[rules.alphaNumeric]" hide-details="auto" maxlength="10"
                      :disabled="disableLicensePlate" @keyup="uppercase" clearable>
                      <template v-slot:label>
                        <span>License Plate</span>
                        <span> *</span>
                      </template></v-text-field> </v-col></v-row>
                <v-row no-gutters class="pt-2 px-2"><v-col cols="1" class="text-right align-self-end">
                    <v-icon class="material-icons-outlined">today</v-icon> </v-col><v-col cols="11" class="px-4">
                    <divrt-date-time-picker :hideTodayBtn="true" :date-picker-props="editStartDatePickerProps"
                      :time-picker-props="editStartTimePickerProps" :isMandatory="true" :rules="rules.isRequired"
                      :max-picker-date="formatDateToISO(
                        new Date(
                          dateToTimeZone(new Date(), zoneDetails.timezone)
                        ).valueOf() +
                        14 * 1000 * 60 * 60 * 24
                      )
                        " :timezone="zoneDetails.timezone || ''" :disabled="isEditStartDatetimeDisabled"
                      v-model="editStartDatetime" label="Start Date"
                      :menu-props="editStartDatetimeMenuProps"></divrt-date-time-picker> </v-col></v-row>
                <v-row no-gutters class="pt-2 px-2"><v-col cols="1" class="text-right align-self-end">
                    <v-icon class="material-icons-outlined">event</v-icon> </v-col><v-col cols="11" class="px-4">
                    <divrt-date-time-picker :hideTodayBtn="true" :date-picker-props="editEndDatePickerProps"
                      :time-picker-props="editEndTimePickerProps" :isMandatory="true"
                      :timezone="zoneDetails.timezone || ''" :max-picker-date="formatDateToISO(
                        new Date(
                          dateToTimeZone(new Date(), zoneDetails.timezone)
                        ).valueOf() +
                        14 * 1000 * 60 * 60 * 24
                      )
                        " v-model="editEndDatetime" :rules="rules.isRequired" label="End Date"
                      :menu-props="editEndDatetimeMenuProps"></divrt-date-time-picker> </v-col></v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center pb-10">
            <v-container class="pa-0" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="px-2 pb-3 pt-3">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                    :loading="addLicencePlateBtnLoading" @click="createHostPass"
                    :disabled="disableSubmitBtn">Submit</v-btn>
                </v-col>
                <v-col cols="12" class=" my-2 px-2 text-center">
                  <v-btn rounded elevation="20" class="white--text " style="background-color: #f2555c" width="180px"
                    height="45px" @click="cancelHostPass">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
          <v-dialog v-model="errDialog" max-width="fit-content">
            <v-card class="pa-0">
              <v-card-title class="justify-center">
                <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
              </v-card-title>
              <v-card-text class="text-center mt-5">
                <span style="font-size: 20px">
                  {{ errMsg }}
                </span>
              </v-card-text>
              <v-card-actions class="text-center pb-6">
                <v-container pa-0 fluid>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                        @click="errDialog = false">OK</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="successDialog" max-width="80%" persistent>
            <v-card class="pa-0">
              <v-card-text class="text-center">
                <v-container fluid pa-2>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-img alt="Success" contain height="175" src="../assets/success_check.gif" />
                      <span style="font-size: 20px">
                        {{ successMsg }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="text-center pb-6">
                <v-container pa-0 fluid>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                        @click="closeSuccessDialog">OK</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import APIHelper from "../apiHelper";
import { ISO_FORMAT } from "@/utils/constants";
import { dateToTimeZoneDate } from "@/utils/formatDateTime";
import rules from "@/utils/rules";
import { format } from "date-fns";
import DivrtDateTimePicker from "../uiComponents/DateTimePicker.vue";

// import loggerHelper from "../loggerHelper";
// import { mapGetters } from "vuex";
export default {
  name: "Createhostpass",
  components: { DivrtDateTimePicker, VuePhoneNumberInput },
  data: () => ({
    merchantID: '',
    isContactValid: true,
    errDialog: false,
    errMsg: "",
    successDialog: false,
    successMsg: "",
    contactObj: {},
    rules: rules,
    licencePlate: "",
    disableLicensePlate: false,
    contactOptions: {
      color: "#0288D1",
      validColor: "#0288D1",
      contactBorderRadius: 0,
      errorColor: "#FF5252",
      defaultCountry: "US",
      translations: {
        phoneNumberLabel: "Contact No",
      },
    },
    editEndDatetime: null,
    editStartDatetime: null,
    isEditStartDatetimeDisabled: false,
    editEndDatePickerProps: {},
    editEndTimePickerProps: {},
    editStartDatePickerProps: {},
    editStartTimePickerProps: {},
    editStartDatetimeMenuProps: {
      transition: "scale-transition",
      "offset-y": true,
      closeOnContentClick: false,
      bottom: true,
    },
    editEndDatetimeMenuProps: {
      transition: "scale-transition",
      "offset-y": true,
      closeOnContentClick: false,
      bottom: true,
    },

    email: "",
    name: "",
    contact: "",
    addLicencePlateBtnLoading: false,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const params = new URLSearchParams(window.location.search)
      vm.getZoneDetails('?zcode', params.get('zcode'))
      vm.name = vm.findUserDetails?.name;
      let guestContact = vm.findUserDetails?.contact ? {
        countryCode:
          vm.findUserDetails?.contact.slice(0, -10) == "91"
            ? "IN"
            : vm.findUserDetails?.contact.slice(0, -10) == "1"
              ? "US"
              : "",
        phoneNumber: vm.findUserDetails?.contact,
        formattedNumber: "+" + vm.findUserDetails?.contact,
      } : vm.contactObj;
      vm.contactObj = Object.assign({}, guestContact);
      vm.editStartDatetime = vm.findUserDetails?.entrytime ? new Date(vm.findUserDetails?.entrytime.replace(/-/g, "/")) : vm.editStartDatetime;
      vm.isEditStartDatetimeDisabled = vm.findUserDetails?.entrytime ? true : false;
      vm.licencePlate = vm.findUserDetails?.vehicleNo ? vm.findUserDetails?.vehicleNo : vm.licencePlate;
      vm.email = vm.findUserDetails?.email;
      vm.disableLicensePlate = vm.findUserDetails?.vehicleNo ? true : false;
      vm.merchantID = params.has('mid') !== null ? params.get('mid') : ''
    });
  },
  computed: {
    ...mapGetters({
      zoneDetails: "getterZoneDetails",
      findUserDetails: "getterFindUserData"
    }),
    disableSubmitBtn() {
      let disabled = (
        this.name == "" ||
        this.name == null ||
        this.email == "" ||
        this.email == null ||
        this.rules.isEmail(this.email) == "Invalid Email" ||
        this.contactObj == null ||
        !this.isContactValid ||
        this.contactObj.phoneNumber == "" ||
        this.contactObj.phoneNumber == null ||
        this.editStartDatetime == "" ||
        this.editStartDatetime == null ||
        this.editEndDatetime == "" ||
        this.editEndDatetime == null ||
        this.licencePlate == "" || this.licencePlate == null || /^[a-z0-9]+$/i.test(this.licencePlate) === false
      );
      // disabled = (this.licencePlate != "" && this.licencePlate != null) ? (disabled || /^[a-z0-9]+$/i.test(this.licencePlate) === false) : disabled;
      return disabled
    },
  },
  watch: {
    editStartDatetime() {
      if (
        this.editEndDatetime != null &&
        this.editEndDatetime != "" &&
        new Date(this.editStartDatetime) > new Date(this.editEndDatetime)
      ) {
        this.editEndDatetime = null;
      }
      this.editEndDatePickerProps = Object.assign(
        {},
        this.editEndDatePickerProps,
        {
          min: format(this.editStartDatetime, "yyyy-MM-dd"),
        }
      );
      this.editEndTimePickerProps = Object.assign(
        {},
        this.editEndTimePickerProps,
        {
          minTime: format(this.editStartDatetime, "HH:mm"),
        }
      );
    },
  },
  created() { },
  async mounted() {
    var self = this;
    const params = new URLSearchParams(window.location.search)
    self.getZoneDetails('?zcode', params.get('zcode'))
    self.merchantID = params.has('mid') !== null ? params.get('mid') : ''
    this.initializeDateTimePicker();
  },
  methods: {
    cancelHostPass() {
      this.successDialog = false;
      this.$router.replace({ path: "/createreservation" });
      this.$store.commit("SET_FINDUSER_DATA", {});
    },
    closeSuccessDialog() {
      this.successDialog = false;
      this.$router.replace({ path: "/createreservation" });
      this.$store.commit("SET_FINDUSER_DATA", {});
    },
    initializeDateTimePicker() {
      if (this.zoneDetails.timezone != "") {
        var tomorrowDate = new Date(
          dateToTimeZoneDate(new Date(), this.zoneDetails.timezone)
        );
        // add a day
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        this.editStartDatetime = this.findUserDetails?.entrytime ? new Date(this.findUserDetails?.entrytime.replace(/-/g, "/")) : new Date(
          dateToTimeZoneDate(new Date(), this.zoneDetails.timezone)
        );
        this.editEndDatetime = new Date(tomorrowDate);
        this.editStartDatePickerProps = Object.assign(
          {},
          this.editStartDatePickerProps,
          {
            min: format(
              dateToTimeZoneDate(new Date(), this.zoneDetails.timezone),
              "yyyy-MM-dd"
            ),
          }
        );
        this.editstartTimePickerProps = Object.assign(
          {},
          this.editStartTimePickerProps,
          {
            minTime: format(this.editStartDatetime, "HH:mm"),
          }
        );
        this.editEndDatePickerProps = Object.assign(
          {},
          this.editEndDatePickerProps,
          {
            min: format(this.editStartDatetime, "yyyy-MM-dd"),
          }
        );
        this.editEndTimePickerProps = Object.assign(
          {},
          this.editEndTimePickerProps,
          {
            minTime: format(this.editStartDatetime, "HH:mm"),
          }
        );
      }
    },
    onContactInput(contactDetails) {
      this.isContactValid = contactDetails?.isValid;
      this.contactObj = Object.assign({}, contactDetails);
    },
    dateToTimeZone(date, timezone) {
      return dateToTimeZoneDate(date, timezone);
    },
    formatDateToISO(date) {
      return format(new Date(date), ISO_FORMAT);
    },
    uppercase() {
      if (rules.alphaNumeric(this.licencePlate) === "Must be alphanumeric") {
        this.licencePlate = this.licencePlate.replace(/[^\w\s]/gi, '')
      }
      this.licencePlate = this.licencePlate.trim()
      this.licencePlate = this.licencePlate.toUpperCase();    
    },
    /**
     * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
     */
    async getZoneDetails(searchParamKey, searchParamValue) {
      try {
        var zoneDetails = await APIHelper(
          "GET",
          "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
        );
        this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
      } catch (error) {
        console.log(error);
      }
      
    },
    /**
     * @method createHostPass to create a new host pass
     */
    async createHostPass() {
      let postData;
      const params = new URLSearchParams(window.location.search)
      // vm.merchantID = params.has('mid') !== null ? params.get('mid') : ''
      if (window.location.search.includes('mid') && this.zoneDetails.selfServeHostPassMerchants.filter((a) => a.uid == params.get('mid')).length == 0) {
        this.errMsg = "Invalid merchant code. Please check"
        this.errDialog = true;
        console.log('hjere')
      }
      else {
        if (Object.hasOwnProperty.call(this.findUserDetails, 'refno') && this.findUserDetails?.refno != "" && this.findUserDetails?.refno != null) {
          postData = {
            name: this.name,
            contact: this.contactObj.formattedNumber.substring(1),
            changeGuestToHostpass: "1",
            bid: this.findUserDetails?.refno,
            zid: this.zoneDetails?.zid,
            email: this.email,
            vehicleNo: this.licencePlate,
            startAt: this.editStartDatetime
              ? format(this.editStartDatetime, "yyyy-MM-dd" + " " + "HH:mm:ss")
              : "",
            stopAt: this.editEndDatetime
              ? format(this.editEndDatetime, "yyyy-MM-dd" + " " + "HH:mm:ss")
              : "",
            mid: this.merchantID != '' && this.merchantID != null && this.merchantID != undefined && this.zoneDetails.selfServeHostPassMerchants.filter((a)=> Number(a.uid) == Number(this.merchantID)).length > 0 ? this.merchantID : this.zoneDetails?.mid,
            locationCode: this.zoneDetails.zcode,
          };
        } else {
          postData = {
            name: this.name,
            contact: this.contactObj.formattedNumber.substring(1),
            zid: this.zoneDetails?.zid,
            email: this.email,
            vehicleNo: this.licencePlate,
            startAt: this.editStartDatetime
              ? format(this.editStartDatetime, "yyyy-MM-dd" + " " + "HH:mm:ss")
              : "",
            stopAt: this.editEndDatetime
              ? format(this.editEndDatetime, "yyyy-MM-dd" + " " + "HH:mm:ss")
              : "",
            mid: this.merchantID != '' && this.merchantID != null && this.merchantID != undefined && this.zoneDetails.selfServeHostPassMerchants.filter((a)=> Number(a.uid) == Number(this.merchantID)).length > 0 ? this.merchantID : this.zoneDetails?.mid,
            locationCode: this.zoneDetails.zcode,
            state: 2
          };
        }

        if (
          this.rules.isEmail(this.email) &&
          this.name !== null &&
          this.name !== "" &&
          this.contactObj !== null &&
          this.contactObj.formattedNumber !== "" &&
          this.editEndDatetime !== null &&
          this.editStartDatetime !== null
        ) {
          var details = await APIHelper(
            "POST",
            "api/v2/merchant/createReservation",
            postData
          );
        }
        if (details?.data?.status) {
          this.successMsg = "Parking pass created successfully";
          this.successDialog = true;
          // this.$store.commit("SET_HOSTPASS_DATA", Object.assign({}, postData, {refno:details?.data?.refno}));
          // this.$router.replace({
          //   name: "SuccessHostPass",
          // });
          // this.errMsg = details?.data?.message;
        } else {
          this.errMsg = details?.data?.message ? details?.data?.message : "";
          this.errDialog = true;
        }
      }
    },
  },
};
</script>
<style lang="less">
.booking_header {
  position: absolute;
  bottom: 30px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.country-selector.md.has-value .country-selector__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
}

.input-tel.md .input-tel__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  margin-left: 2px;
}

.input-tel.is-focused.has-error .input-tel__input {
  box-shadow: none !important;
}

.input-tel.is-focused.is-valid .input-tel__input {
  box-shadow: none !important;
}

.information {
  background-color: #edf1f4;
}
</style>
  